import { ssrGetNavigation } from '../../@types/codegen/page';

export const serverSideNavigation = async () => {
  try {
    const res = await ssrGetNavigation.getServerPage({});

    return res.props;
  } catch (e) {
    console.error('serverSideNavigation error:', e);
    return {
      apolloState: {
        ROOT_QUERY: {},
      },
      data: {},
    };
  }
};
