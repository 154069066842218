import path from 'path';
import { UserConfig } from 'next-i18next';

const tenant = process.env.TRANSLATION_TENANT || 'default';

export const i18nextConfig: UserConfig = {
  i18n: {
    defaultLocale: 'de',
    locales: ['de'],
  },
  localePath: path.resolve('./public/static/locales/' + tenant),
  saveMissing: true,
  react: {
    useSuspense: false,
  },
};
