import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import type { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';
import type { BaseType } from 'antd/es/typography/Base';

import { FontSecondaryLight, FontSecondarySemiBold } from '../../../../styles/fonts';
import { mediaQueries } from '../../../../styles/media-queries';
import { spacingTop } from '../../../../helper/spacing';

interface IHeadlineStyleProps {
  type?: BaseType;
  variant?: 1 | 2 | 3 | 4 | 5 | 6;
}

export interface ITitleProps extends AntdTitleProps, IHeadlineStyleProps {}

export const Title: FC<ITitleProps> = (props) => {
  let level = props?.level ?? 1;
  const variant = props.variant ?? props.level;

  if (5 < level) {
    level = 5; // H6 and above is not defined in antd.
  }

  return (
    <StyledTypography className={`title-variant-${variant}`} {...props} level={level}>
      {props.children}
    </StyledTypography>
  );
};

Title.defaultProps = {
  ellipsis: false,
};

const StyledTypography = styled(Typography.Title)`
  &.title-variant-1,
  &.title-variant-2,
  &.title-variant-3,
  &.title-variant-4 {

    &.ant-typography,
    &.ant-typography * {
      ${(p) => ('secondary' === p.type ? FontSecondaryLight : FontSecondarySemiBold)}
      color: inherit;
      white-space: pre-wrap;
    }
  }

  &.title-variant-5,
  &.title-variant-6 {

    &.ant-typography,
    &.ant-typography * {
      ${FontSecondarySemiBold};
      color: inherit;
      white-space: pre-wrap;
      font-size: var(--font-size-text);
      line-height: 24px;
      ${spacingTop(4)};
    }
  }

  &.title-variant-1.ant-typography,
  &.ant-typography h1,
  &.title-variant-1.ant-typography *,
  &.ant-typography h1 * {
    font-size: var(--font-size-headline-h1-mobile);
    line-height: var(--line-height-headline-h1-mobile);

    @media ${mediaQueries.laptop} {
      font-size: var(--font-size-headline-h1);
      line-height: var(--line-height-headline-h1);
    }
  }

  &.title-variant-2.ant-typography,
  &.ant-typography h2,
  &.title-variant-2.ant-typography *,
  &.ant-typography h2 * {
    font-size: var(--font-size-headline-h2-mobile);
    line-height: var(--line-height-headline-h2-mobile);

    @media ${mediaQueries.laptop} {
      font-size: var(--font-size-headline-h2);
      line-height: var(--line-height-headline-h2);
    }
  }

  &.title-variant-3.ant-typography,
  &.ant-typography h3,
  &.title-variant-3.ant-typography *,
  &.ant-typography h1 * {
    font-size: var(--font-size-headline-h3-mobile);
    line-height: var(--line-height-headline-h3-mobile);

    @media ${mediaQueries.laptop} {
      font-size: var(--font-size-headline-h3);
      line-height: var(--line-height-headline-h3);
    }
  }

  &.title-variant-4.ant-typography,
  &.ant-typography h4,
  &.title-variant-4.ant-typography *,
  &.ant-typography h4 * {
    font-size: var(--font-size-headline-h4-mobile);
    line-height: var(--line-height-headline-h4-mobile);

    @media ${mediaQueries.laptop} {
      font-size: var(--font-size-headline-h4);
      line-height: var(--line-height-headline-h4);
    }
  }
`;
