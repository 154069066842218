import React, { FC } from 'react';
import {
  spacingCalculator,
  spacingBottom,
  spacingLeft,
  spacingRight,
  theme,
  FontPrimarySemiBold,
  spacingTop,
  FontSecondarySemiBold, spacingAround,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import Link from 'next/link';

import { mediaQueries } from '../../styles/media-queries';

export interface IPopularCategory {
  id: string;
  title: string;
  relativeUrl: string;
  imageUrl: string;
}

interface IPopularCategoriesStaticProps {
  popularCategories: IPopularCategory[];
  headlineTitle: string;
  invertButtonColor?: boolean;
}

export const PopularCategoriesStatic: FC<IPopularCategoriesStaticProps> = ({ headlineTitle, popularCategories, invertButtonColor = false }) => {
  let splitByTwo = false;

  if (0 !== popularCategories.length % 3 && 0 === popularCategories.length % 2) {
    splitByTwo = true;
  }

  const renderCategories = (): JSX.Element[] => {
    return popularCategories?.map(category => {
      return (
        <Link passHref prefetch={false} href={category.relativeUrl} key={category.id}>
          <PopularCategory invertButtonColor={invertButtonColor}>
            <CategoryIcon url={category.imageUrl} invertButtonColor={invertButtonColor} />
            <CategoryTitle invertButtonColor={invertButtonColor}>{category.title}</CategoryTitle>
          </PopularCategory>
        </Link>
      );
    }) ?? [];
  };

  return (
    <ComponentWrapper>
      {headlineTitle && 0 < headlineTitle.length && (
        <Headline>
          <CategoryHeader>{headlineTitle}</CategoryHeader>
        </Headline>
      )}

      <CategoriesWrapper splitByTwo={splitByTwo}>
        {renderCategories()}
      </CategoriesWrapper>
    </ComponentWrapper>
  );
};

export const CategoryHeader = styled.p`
  ${FontSecondarySemiBold};
  color: var(--search-header-font-color);
  
  font-size: var(--font-size-headline-h2);
  line-height: calc(var(--font-size-headline-h2) + 2px);

  @media ${mediaQueries.tablet} {
    font-size: var(--font-size-headline-h2);
    line-height: calc(var(--font-size-headline-h2) + 2px);
  }
`;

const ComponentWrapper = styled.div`
  justify-self: center;
  ${spacingTop(4)};

  @media ${mediaQueries.laptop} {
    ${spacingTop(6)};
  }
`;

const CategoriesWrapper = styled.div<{ splitByTwo: boolean }>`
  display: grid;
  grid-template-columns: auto;
  grid-gap: ${spacingCalculator(1)};
  flex-wrap: wrap;

  > a {
    ${spacingAround(0.5)};
    flex: 100%;
    vertical-align: middle;
  }

  @media ${mediaQueries.tablet} {
    grid-template-columns: ${(p) => p.splitByTwo ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
  }
`;

export const CategoryIcon = styled.div<{ url: string, invertButtonColor: boolean }>`
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;

  @media ${mediaQueries.tablet} {
    width: 50px;
    height: 50px;
  }

  background-color: ${(p): string => p.invertButtonColor ? 'var(--white)' : 'var(--secondary-color)'};
  mask: ${(p): string => p.url ? `url(${p.url})` : ''};
  mask-size: contain;
  margin-left: ${spacingCalculator(1)};
`;

const CategoryTitle = styled.span<{ invertButtonColor: boolean }>`
  display: inline-block;
  ${FontSecondarySemiBold};
  vertical-align: middle;
  font-size: 20px;
  margin-left: ${spacingCalculator(2)};

  color: ${(p): string => p.invertButtonColor ? 'var(--white)' : 'var(--secondary-color)'};
  word-break: break-word;

  @media ${mediaQueries.tablet} {
    ${FontPrimarySemiBold};
    ${spacingLeft(1)};
    ${spacingRight(1)};
    font-size: 18px;
    line-height: 22px;
  }
`;

const Headline = styled.div`
  color: ${theme.white};
  text-align: center;
  ${spacingBottom(3)};
  ${spacingTop(1)};

  @media ${mediaQueries.tablet} {
    text-align: center;
    font-size: 32px;
  }
`;

const PopularCategory = styled.div<{ invertButtonColor: boolean }>`
  display: flex;
  white-space: nowrap;
  padding: ${spacingCalculator(2)};
  border-radius: calc(var(--input-button-border-radius) / 2);
  background-color: ${(p): string => p.invertButtonColor ? 'var(--secondary-color)' : 'var(--white)'};
  align-items: center;

  @media ${mediaQueries.tablet} {
    padding: ${spacingCalculator(1)};
  }
`;
