import { ssrRecommendedProductsSlice } from '../../../@types/codegen/page';
import { ComponentContentRecommendedCategories } from '../../../@types/codegen/graphql';
import { ZoneCacheFunc } from '../../zone-cache';



export const recommendedProductsCachePrefetch: ZoneCacheFunc<ComponentContentRecommendedCategories> = async (zone) => {
  const tenantKey = zone?.tenantKey;
  const category = zone?.categories?.[0];

  if (!category) {
    return;
  }

  const productTypeUriComponents = category.products
    ?.map((product) => product?.uriComponent)
    .filter((uriComponent) => uriComponent) as string[];

  const res = await ssrRecommendedProductsSlice.getServerPage({
    variables: {
      recommendedProductParams: {
        tenantKeys: tenantKey ? [tenantKey] : [],
        productTypeUriComponents: productTypeUriComponents,
        countryCode: null, // TODO: Add country code when recommended products are used on a non cached page
      },
    },
  });

  return {
    apolloState: res.props.apolloState,
    data: res.props.data,
  };
}
