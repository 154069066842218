import { ssrRentalStations } from '../../../@types/codegen/page';
import { ComponentContentRentalStations } from '../../../@types/codegen/graphql';
import { ZoneCacheFunc } from '../../zone-cache';

export const rentalStationsCachePrefetch: ZoneCacheFunc<ComponentContentRentalStations> = async ({tenantKey}) => {
  const res = await ssrRentalStations.getServerPage({
    variables: {
      params: {
        tenantKey: tenantKey,
      }
    },
  });

  return {
    apolloState: res.props.apolloState,
    data: res.props.data,
  };
}
