import { useGlobalSearchValues, useSetGlobalSearch } from './../store/recoil/global-search';
import { useEffect, useState } from 'react';

export const usePageHeaderSearch = (height: number): void => {
  const { mainSearchVisible } = useGlobalSearchValues();
  const { setMainSearchVisible } = useSetGlobalSearch();
  const [enableCall, setEnableCall] = useState<boolean>(true);

  useEffect(() => {

    const onScroll = function (): void {
      if (!enableCall) return;

      if (height - 300 < window.scrollY && mainSearchVisible) {
        setMainSearchVisible(false);
      }
      if (height - 300 > window.scrollY && !mainSearchVisible) {
        setMainSearchVisible(true);
      }
    };

    setTimeout(() => setEnableCall(true), 300);

    document.addEventListener('scroll', onScroll);
    onScroll();

    return (): void => {
      document.removeEventListener('scroll', onScroll);
    };

  }, [height, mainSearchVisible]);
};
