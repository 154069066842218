import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const generateImageUrl = (path: string) => {
  if (!path) {
    return '';
  }

  if (path.startsWith('http')) {
    return path;
  }

  const url = new URL(path, publicRuntimeConfig.strapi.url);

  return url.toString();
};
