import getConfig from "next/config";
import { IOrganizationAdress } from "./digando-organization.schema";

const {publicRuntimeConfig} = getConfig();

interface IContactPoint {
  telephone: string,
  email: string,
  contactType: string
}
interface IOnlineStoreShema {
  name: string;
  slogan: string;
  taxID: string;
  contactPoint: IContactPoint;
  address: IOrganizationAdress;
  foundingDate: string;
  iso6523Code: string;
}

export const onlineStoreSchema = ({
  name,
  slogan,
  taxID,
  contactPoint,
  address,
  foundingDate,
  iso6523Code
} : IOnlineStoreShema) => {
  return {
    "@context": "https://schema.org",
    "@type": "OnlineStore",
    name,
    "url": publicRuntimeConfig.appBaseUrl,
    "sameAs": [
      publicRuntimeConfig.socialMedia.facebookUrl,
      publicRuntimeConfig.socialMedia.linkedinUrl,
      publicRuntimeConfig.socialMedia.instagramUrl,
    ],
    "logo": publicRuntimeConfig.logoUrl,
    slogan,
    "acceptedPaymentMethod": [
      "Invoice"
    ],
    taxID,
    contactPoint,
    "address": {
      "@type": "PostalAddress",
      ...address
    },
    foundingDate,
    iso6523Code
  }
};