import React, { FC } from 'react';
import {
  ComponentContentFaq,
  FaqEntity,
  AllZonesFragment,
} from '../@types/codegen/graphql';
import { stripHtml } from '../lib/strip-html';
import { isFeatureFlagEnabled } from '../lib/environment';

interface FaqPageSchemaProps {
  zones: AllZonesFragment[];
}

const FaqPageSchemaComponent: FC<FaqPageSchemaProps> = ({ zones = []}) => {
  if (!isFeatureFlagEnabled('isSchemaMarkupEnabled')) {
    return null;
  }

  const faqs: FaqEntity[] = zones
    .filter(zone => zone && 'ComponentContentFaq' === zone.__typename)
    .map(zone => (zone as ComponentContentFaq)?.faqs?.data ?? [])
    .flat();

  if (0 === faqs.length) {
    return null;
  }

  const structuredFAQJSON = faqs.map((faq) => {
    return {
      '@type': 'Question',
      'name': faq.attributes?.question ?? '',
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': stripHtml(faq.attributes?.answer ?? ''),
      }
    };
  }) ?? [];

  const structuredJSON = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': structuredFAQJSON
  };

  return (
    <>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredJSON) }} />
    </>
  );
};

export const FaqPageSchema = React.memo(FaqPageSchemaComponent, () => true);
