import getConfig from 'next/config';

import { ssrGeneratePageUrl } from '../lib/strapi/ssr-generate-page-url';
import { CategoryFragment } from '../@types/codegen/graphql';

const { publicRuntimeConfig } = getConfig();

export const websiteSchema = (category?: CategoryFragment, location?: string) => {
  const searchActionParameterTemplate = '?q={search_term_string}'
  const relativePath = ssrGeneratePageUrl('search-page', category?.uriComponent, category ? [category] : []);
  const absolutePath = publicRuntimeConfig.appBaseUrl + relativePath;
  const locationSuffix = location ? `/l/${location}` : '';
  const searchActionUrlTemplate = absolutePath + locationSuffix + searchActionParameterTemplate

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'url': publicRuntimeConfig.appBaseUrl,
    'potentialAction': {
      '@type': 'SearchAction',
      'target': {
        '@type': 'EntryPoint',
        'urlTemplate': `${searchActionUrlTemplate}`,
      },
      'query-input': 'required maxlength=100 name=search_term_string',
    },
  };
};